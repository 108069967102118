@font-face {
  font-family: "IM";
  src: url("./../assets/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "ISB";
  src: url("./../assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "IB";
  src: url("./../assets/fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "AVR";
  src: url("./../assets/fonts/Metropolis-Regular.otf") format("opentype");
}

@font-face {
  font-family: "AVSB";
  src: url("./../assets/fonts/Metropolis-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "AVB";
  src: url("./../assets/fonts/Metropolis-Bold.otf") format("opentype");
}
